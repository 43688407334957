@import url(https://fonts.googleapis.com/css2?family=Zen+Kaku+Gothic+Antique:wght@300&display=swap);

*{
    margin: 0;
    padding: 0;
    font-family: 'Zen Kaku Gothic Antique', sans-serif;
    /* background-color: #000; */
}

.webgl{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
    /* z-index: 3; */
}

html,
body{
    overflow: hidden;
}

.infobox{
    position: absolute;
    top: 50%;
    left: 50%;
}

.text{
    top: 30px;
    left: -120px;
    width: 400px;
    height: 300px;
    padding: 20px;
    color: #fff;
    font-weight: 100;
    font-size: 18px;
    transform: translateX(-10000px);

}

a{
    color: rgb(138, 187, 255);
    text-decoration: none;
}

.list-item{
    opacity: 0;
    transform: translateX(-10000px);

}

.scale-down{
    scale: 0;
}

.scale-up{
    scale: 1;
}

.l0{
    webkit-animation: fade-in 1s linear 0.5s;
    animation: fade-in 1s linear 0.5s;
    animation-fill-mode: forwards;

}

.l1{
    webkit-animation: fade-in 1s linear 1s;
    animation: fade-in 1s linear 1s;
    animation-fill-mode: forwards;

}

.l2{
    webkit-animation: fade-in 1s linear 1.5s;
    animation: fade-in 1s linear 1.5s;
    animation-fill-mode: forwards;

}

.l3{
    webkit-animation: fade-in 1s linear 2s;
    animation: fade-in 1s linear 2s;
    animation-fill-mode: forwards;

}

#codeInfobox {
    opacity: 0.0;
    z-index: 3;
}

#musicInfobox{
    opacity: 0.0;
    z-index: 2;
}

ul{
    list-style-type: none;
}

li{
    line-height: 2.2;
}

.animated-text{
    animation: fade-in 1s;
}

.infobox.visible{
    transform:scale(1,1);
}

@keyframes fade-in {
    0%{
        transform: translateX(100px);
        opacity: 0;
        /* color: #000; */
        /* transform: scale(0); */
    }
    100%{
        transform: translateX(0px);
        opacity: 1;
        /* color: #fff; */
        /* transform: scale(1); */
    }
    
}


#box1{
    top: 25%;
    left: 40%;
    height: 50px;
    width: 50px;
    border: 2px solid gray;
    margin-top: 0em;
    margin-left: 0em;
    position: absolute;
    box-sizing: border-box;
    animation: spin1 2s linear infinite;
}

#box2{
    top: 25%;
    left: 40%;
    height: 50px;
    width: 50px;
    border: 2px solid gray;
    margin-top: 50px;
    margin-left: 50px;
    position: absolute;
    padding-left: 2px;
    box-sizing: border-box;
    animation: spin2 2s linear infinite;
}

@keyframes spin1{
    0%{
        transform: rotate(0deg);
        margin-left: 0px;
        margin-top: 0px;
    }
    50%{
        margin-left: 50px;
        margin-top: 0px;
    }
    100%{
        margin-top: 50px;
        margin-left: 50px;
        transform: rotate(360deg);
    }
}

@keyframes spin2{
    0%{
        margin-left: 50px;
        margin-top: 50px;
    }
    50%{
        margin-left: 0px;
        margin-top: 50px;
    }
    100%{
        margin-top: 0px;
        margin-left: 0px;
        transform: rotate(360deg);
    }
}